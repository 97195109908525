<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->

		<!-- 门店定位 -->
		<div class="card no-b pb-5">
			<div class="card-header bg-white">
				<div class="float-left">
					<h4 class="mt-2">门店定位设置</h4>
		
				</div>
				<div class="float-right">
					<!-- <button @click="back" type="type" class="btn btn-outline-dark">
						<i class="icon icon-chevron-circle-left"></i>返回
					</button> -->
					<button @click="saveStore" type="type" class="btn btn-dark ml-2"> 保存 </button>
				</div>
			</div>
		
			<div class="card-body">
		
				<!-- 门店位置 start -->
				<div class="box-body no-padding">
					<div class="m-3">
		
		
						<div class="row">
							<div class="col-md-8">
								<div class="amap-wrapper">
									<div class="mb-3">
										店铺定位，骑手取货导航用，非常重要, <span class="text-danger">请在地图上选点</span>
									</div>
									<el-amap-search-box class="search-box"
										style="margin-bottom: 10px; border: 1px solid #eee !important; box-shadow: none;"
										:search-option="searchOption" :on-search-result="onSearchResult">
									</el-amap-search-box>
		
									<!-- 编辑 -->
									<template v-if="tempStore.longitude">
										<el-amap :center="center" :events="events" zoom="15" ref="mymap"
											class="amap-box" vid="amap">
											<el-amap-marker v-for="(marker,index) in markers"
												:position="marker.position" v-bind:key="index">
											</el-amap-marker>
											<el-amap-info-window autoMove="true" showShadow="true"
												:offset="[0,-32]" :position="window.position"
												:content="window.content">
											</el-amap-info-window>
										</el-amap>
									</template>
		
									<!-- 设置 -->
									<template v-if="!tempStore.longitude">
										<el-amap :events="events" zoom="15" ref="mymap" class="amap-box"
											vid="amap">
											<!-- <el-amap-marker v-for="(marker,index) in markers"
											:position="marker.position" v-bind:key="index">
										</el-amap-marker>
										<el-amap-info-window autoMove="true" showShadow="true"
											:offset="[0,-32]" :position="window.position"
											:content="window.content">
										</el-amap-info-window> -->
										</el-amap>
									</template>
								</div>
							</div>
							<div class="col-md-4">
								<!-- center:{{center}}//<br>
								marksers:{{markers}}//<br>
								window:{{window}} -->
								<div class="form-group">
									<label for="inputCity" class="col-form-label">店铺名称</label>
									<input v-model="tempStore.storeName" class="form-control"
										placeholder="店铺名称" type="text">
									<div class="text-muted">名称支持16个中文字符或32个英文字符,并且不包含 /\\'*&^\"#!$ 等特殊字符</div>
								</div>
								<div class="form-group">
									<label for="inputZip" class="col-form-label">店铺电话</label>
									<input v-model="tempStore.phone" class="form-control" placeholder="店铺电话"
										type="text">
									<div class="text-muted">骑手取货、收货人联系用</div>
								</div>
		
								<div class="form-group">
									<label for="inputCity" class="col-form-label">店铺地址</label>
		
									<div class="form-row">
										<div class="col">
											<select v-model="tempStore.provinceId"
												@change="onChangeProvince" class="form-control">
												<option value="">省</option>
												<template v-for="(item, index) in provinces">
													<option :value="item.province_id" v-bind:key="index">
														{{item.province_name}}</option>
												</template>
											</select>
										</div>
										<div class="col">
											<select v-model="tempStore.cityId" @change="onChangeCity"
												class="form-control" v-if="tempCities.length>0">
												<option value="">市</option>
												<template v-for="(item, index) in tempCities">
													<option :value="item.city_id" v-bind:key="index">
														{{item.city_name}}</option>
												</template>
											</select>
										</div>
										<div class="col">
											<select v-model="tempStore.districtId" class="form-control"
												v-if="tempDistricts.length>0">
												<option value="">区</option>
												<template v-for="(item, index) in tempDistricts">
													<option :value="item.district_id" v-bind:key="index">
														{{item.district_name}}</option>
												</template>
											</select>
										</div>
									</div>
								</div>
								<div class="form-group">
									<label for="inputState" class="col-form-label">
									</label>
									<input v-model="tempStore.storeAddress" class="form-control"
										placeholder="店铺地址" type="text">
									<div class="text-muted">详细地址，填写清晰，骑手取货用</div>
								</div>
		
								<div class="form-group">
									<label for="inputZip" class="col-form-label">经纬度</label>
									<input v-model="tempStore.longitude" readonly
										class="form-control-plaintext" placeholder="" type="text">
									<input v-model="tempStore.latitude" readonly
										class="form-control-plaintext" placeholder="" type="text">
									<div class="text-muted"></div>
								</div>
		
							</div>
						</div>
		
						<!-- </div> -->
		
		
					</div>
				</div>
				<!-- 门店位置 end -->
		
			</div>
		</div>
		<!-- //门店定位 -->

		<!-- //main content -->


		<!-- modal -->
		<!-- 商户类型 -->
		<div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">指定商户类型</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="form-row">

								<div class="col-md-12 mb-3">
									<select  class="form-control" v-model="take_away_category_index">
										<option v-bind:key="index" :value="index"
											v-for="(item,index) in take_away_category_list">{{item.name}}</option>
									</select>
								</div>

							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveTakeAwayCategory" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- 指定商户 -->
		<div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">指定商户</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="row">
								<div class="col">
									<input v-model="query" type="text" placeholder="搜索PID/商户名称..." class="form-control" />
								</div>
								<div class="col">
									<div class="float-right">
										<button @click="searchMerchant" type="button" class="btn btn-dark btn-sm"> 搜索
										</button>
									</div>
								</div>
							</div>
							<hr>
							<div class="card">
								<div class="card-body no-m p-3 grey lighten-2">
									<div class="form-check">
										<label class="form-check-label">
											<input v-model="check_all"  @click.stop="checkAll" class="form-check-input" type="checkbox"> 所有
										</label>
									</div>
								</div>
							</div>
							
							<template v-for="(item, index) in merchant_list">
								<div class="card mt-1 mb-1" v-bind:key="index">
									<div class="card-body no-m p-3 bg-light">
										<div class="row">
											<div class="col-md-1">
												<input v-model="checkedIds" :value="item.id" :id="index" type="checkbox"
													:disabled="item.enabled == 0">
											</div>

											<div class="col-md-4">
												{{item.name}}
											</div>

											<div class="col-md-3">
												{{item.state}}{{item.city}}
											</div>
											<div class="col-md-4">
												{{item.street}}
											</div>
										</div>
									</div>
								</div>
							</template>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveTaste" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>


		



		<!-- //modal -->
	</div>
</template>

<script>
	// import DatePicker from 'vue2-datepicker';
	// import 'vue2-datepicker/index.css';
	// import 'vue2-datepicker/locale/zh-cn';

	export default {
		data() {
			return {
				title: '创建商品',

				token: '',

				institution: {
					dept_id: '',
					expense_type: '',
					expense_type_sub_category: '',
				},

				disabled: false,

				categorylist: [],
				category: {}, //选中的会员级别

				setting: {},

				//以下扫码点餐商品
				specList: [],
				tempSpecList: [], //临时

				tasteList: [],
				tempTasteList: [],

				attachList: [],
				tempAttachList: [],

				outletId: 0, //登录店铺IDs
				institutionImg: '',

				selectedOutletId: 0,
				selectedOutletName: '',
				selectedCatId: 0,
				selectedCatName: '',
				selectedBrandId: 0,
				selectedBrandName: '',
				selectedSupplierId: 0,
				selectedSupplierName: '',
				selectedVipDiscountTypeDesc: '售价*会员折扣率',
				selectedCommissionTypeDesc: '无提成',

				old_institution_name: '',
				old_barcode: '',

				isMore: false,

				//图片上传
				files: [],
				maxSize: 1024 * 10, // 10 KB
				previewIMG: null,
				limit: 1,
				isPreview: false,
				type: 2, // 0 预览模式 1 列表模式 2 预览模式 + 上传按钮
				
				////
				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				
				scenetypelist: [
					{name: '通用(默认)', value: 'DEFAULT'}
				],
				expensetypelist: [
					{name: '选择费用类型', value: ''},
					{name: '通用(默认)', value: 'DEFAULT'},
					{name: '餐饮', value: 'MEAL'}
				],
				subexpensetypelist: [
					{name: '选择制度类型', value: ''},
					{name: '通用', value: 'DEFAULT'},
					{name: '餐饮-到店', value: 'REACH_SHOP'},
					{name: '餐饮-外卖', value: 'TAKE_AWAY'}
				],
				
				mch_scope: 0,//可用商户，0不限，1商户类型，2指定商户
				mcc_list: [],//餐饮商户类型二级分类
				//到店餐饮商户类型二级分类
				sub_mcc_list: [
					{name: '不限', value: '-1'},
				],
				//外卖 餐饮商户类型一级分类
				take_away_category_list: [
					{name: '全部餐饮美食', value: 'MEAL'},
					{name: '快餐便当', value: 'FAST_FOOD_LUNCH'},
					{name: '中式菜系', value: 'CHINESE_CUISINE'},
					{name: '小吃/烧烤', value: 'SNACKS_BBQ'},
					{name: '奶茶果汁', value: 'DESSERT_DRINKS'},
					{name: '全球美食', value: 'GLOBAL_CUISINE'},
					{name: '香锅火锅', value: 'HOT_POT'},
				],
				take_away_category: 'MEAL',
				take_away_category_index: 0,
				
				merchant_list: [],
				
				// current_date: '',
				end_date: 0,
				
				query: '',
				check_all: false,
				checkedIds: [],
				
				////
				tempStore: {},
				isShowLocation: true,
				center: [],
				markers: [],
				window: {},
				searchOption: {
					city: '上海',
					citylimit: false
				},
				
				events: {
					click: (e) => {
						console.log('--- e ->', e)
						// this.mapInfo.lng = e.lnglat.lng;
						// this.mapInfo.lat = e.lnglat.lat;
						// this.mapInfo.lnglat = [e.lnglat.lng, e.lnglat.lat];
				
						this.chooseLocation(e.lnglat.lng, e.lnglat.lat)
					}
				},
				address: '',
				
				provinces: [],
				cities: [],
				districts: [],
				
				tempCities: [],
				tempDistricts: [],
				province_id: '',
				city_id: '',
				district_id: '',
				
				//
				deliveryOption: {},
				tempDeliveryOption: {},
			}
		},

		components: {
			// DatePicker
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->', this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;

			self.setting = JSON.parse(localStorage.getItem('SETTING'));

			self.merchant_list = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.merchant_list)

			//指定店铺
			// let selectedOutletId = getQueryString("outlet_id");
			console.log('--- this.$route>', this.$route)
			let selectedOutletId = this.$route.params.outlet_id;
			if (selectedOutletId) {
				self.outlet_id = selectedOutletId;
			}

			//是否编辑
			let selectedProductBarcode = this.$route.query.no;
			if (selectedProductBarcode) {
				var _params = this.$route.query;
				// self.selectedOutletId = _params.outlet_id;
				self.resetProduct(_params);
			}
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }

			self.init();
		},
		methods: {
			init(callback) {
				let self = this;
			
				//
				this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
						params: {
							token: self.token,
							// dept_id: self.dept_id,
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.departmentlist = data.data.departments;
							
							for (let i = 0; i < self.departmentlist.length; i++) {
								let dept = self.departmentlist[i];
								self.deptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.deptmap);
							
							self.institution.effective_start_date = data.data.current_date;
							
							if (typeof callback == 'function') {
							    callback()
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},
			
			initData() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getCyCategoryList', {
						params: {
							token: self.token,
							outlet_id: self.outlet_id,
							// name: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.categorylist = data.data;

							if (self.categorylist.length > 0) {
								if (!self.institution.id) { //新增时默认第一个
									self.institution.category_id = self.categorylist[0].id || 0;
								}
							} else {
								// alert('该店铺没有商品分类');
								self.$toasted.error('该店铺没有商品分类', {
									position: 'top-center',
								}).goAway(2000)
							}

							// __LIST = data.data;
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END

			},

			//添加(用于消费机)
			add() {
				let self = this;
				console.log(self.institution);
				// return;

				//校验
				if (self.institution.category_id == 0) {
					// alert('请选择商品分类')
					self.$toasted.error('请选择商品分类', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.institution_name) {
					// alert('品名不能为空')
					self.$toasted.error('品名不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.price) {
					// alert('价格不能为空')
					self.$toasted.error('价格不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.unit) {
					// alert('规格不能为空')
					self.$toasted.error('规格不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}


				self.disabled = true;

				// let tags = $(".tags-input").tagsinput('items');
				// let tag = tags.length>0?tags.join():'';

				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,

					institution_name: self.institution.institution_name,
					price: self.institution.price || 0,
					unit: self.institution.unit,
					category_id: self.institution.category_id,

					kitchen_print: 0,
					scale_flag: 0,
				}

				this.axios.get(this.GLOBAL.baseURI + 'addCyProduct', { //调用简易录菜接口
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							// alert('保存成功')
							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)
							self.$router.push({
								path: 'institutions',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.initData();
			},

			//以下扫码点餐

			// 规格
			addSpec() {
				let self = this;

				self.tempSpecList.push({
					id: 0,
					unit: '',
					item_no: '',
					price: '',
					vip_price: '',
					supply_price: ''
				})
			},


			editSpes() {
				let self = this;

				self.tempSpecList = Object.assign([], self.specList);

				if (self.tempSpecList.length == 0) {
					self.tempSpecList.push({
						id: 0,
						unit: self.institution.unit,
						item_no: '',
						price: self.institution.price,
						vip_price: '',
						supply_price: ''
					})
				}

				$('#categoryModal').modal('show');
			},

			deleteSpec(index) {
				let self = this;

				self.tempSpecList.splice(index, 1);
			},

			saveSpec() {
				let self = this;
				console.log('--- tempSpecList @ saveSpec->', self.tempSpecList);
				// return;

				//校验
				for (let i = 0; i < self.tempSpecList.length; i++) {
					let item = self.tempSpecList[i];
					console.log('--- item  -> ', item)
					if (item.unit == '') {
						// alert('第' + (i + 1) + '个规格名称为空')
						self.$toasted.error('第' + (i + 1) + '个规格名称为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}

					if (!item.price) {
						// alert('第' + (i + 1) + '个规格价格为空')
						self.$toasted.error('第' + (i + 1) + '个规格价格为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}
				// return;


				// console.log('---  tempSpecList ->',JSON.stringify(self.tempSpecList))

				self.specList = Object.assign([], self.tempSpecList);

				if (self.specList.length == 1) {
					self.institution.unit = self.specList[0].unit;
					self.institution.price = self.specList[0].price;
				}

				$('#categoryModal').modal('hide');

			},

			// 偏好
			addTaste() {
				let self = this;

				if (self.tempTasteList.length >= 5) {
					// alert('最多可设置5种偏好')
					self.$toasted.error('最多可设置5种偏好', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.tempTasteList.push({
					name: '',
					values: ''
				})
			},


			editTastes() {
				let self = this;

				self.tempTasteList = Object.assign([], self.tasteList);
				// self.tempTasteList.push({
				// 	name: '',
				// 	values: ''
				// })

				$('#searchModal').modal('show');
			},

			deleteTaste(index) {
				let self = this;

				self.tempTasteList.splice(index, 1);
			},

			saveTaste() {
				let self = this;
				console.log('--- tempTasteList @ saveTaste->', self.tempTasteList);
				// return;

				//校验
				for (let i = 0; i < self.tempTasteList.length; i++) {
					let item = self.tempTasteList[i];
					console.log('--- item  -> ', item)
					if (item.name == '') {
						// alert('第' + (i + 1) + '个偏好名称为空')
						self.$toasted.error('第' + (i + 1) + '个偏好名称为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}

					if (!item.values) {
						// alert('第' + (i + 1) + '个偏好为空')
						self.$toasted.error('第' + (i + 1) + '个偏好为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}
				// return;


				// console.log('---  tempTasteList ->',JSON.stringify(self.tempTasteList))

				self.tasteList = Object.assign([], self.tempTasteList);

				$('#searchModal').modal('hide');

			},


			// 加料
			editAttachs() {
				let self = this;

				self.tempAttachList = Object.assign([], self.attachList);
				// self.tempAttachList.push({
				// 	name: '',
				// 	price: ''
				// })

				$('#attachModal').modal('show');
			},

			addAttach() {
				let self = this;

				// if(self.tempAttachList.length>=5){
				//  alert('最多可设置10种加料')
				//  return;
				// }

				self.tempAttachList.push({
					name: '',
					price: ''
				})
			},

			deleteAttach(index) {
				let self = this;

				self.tempAttachList.splice(index, 1);
			},

			saveAttach() {
				let self = this;
				console.log('--- tempAttachList @ saveAttach >', self.tempAttachList);
				// return;

				//校验
				for (let i = 0; i < self.tempAttachList.length; i++) {
					let item = self.tempAttachList[i];
					console.log('--- item  -> ', item)
					if (item.unit == '') {
						// alert('第' + (i + 1) + '个加料菜名为空')
						self.$toasted.error('第' + (i + 1) + '个加料菜名为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}

					if (!item.price) {
						// alert('第' + (i + 1) + '个加料价格为空')
						self.$toasted.error('第' + (i + 1) + '个加料价格为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}
				// return;

				self.attachList = Object.assign([], self.tempAttachList);

				$('#attachModal').modal('hide');

			},

			// 商品选项
			onProductOptionChange(key) {
				console.log('--- key  -> ', key)
				let self = this;

				if (key == 'active') {
					self.institution[key] = self.institution[key] == 'ON' ? 'OFF' : 'ON';
				} else {
					self.institution[key] = self.institution[key] == 1 ? 0 : 1;
				}

				if (key == 'scale_flag' && self.institution.scale_flag == 1) {
					self.institution.unit = '公斤';
				} else {
					self.institution.unit = '份';
				}

				console.log('--- institution  -> ', self.institution)
			},

			onstatechange(state) {
				console.log('--- state  -> ', state)
			},

			save() {
				let self = this;
				console.log(self.institution);
				// return;


				//0.构建institution对象数据
				// self.buildInstitution();
				// console.log('--- institution after build->', self.institution)

				//1.校验
				if (self.institution.dept_id == '') {
					self.$toasted.error('请选择归属企业', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.expense_type_sub_category == '') {
					self.$toasted.error('请选择制度类型', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.institution_name) {
					self.$toasted.error('制度名称不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.quota_total) {
					self.$toasted.error('额度金额不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (self.institution.quota_total == 0) {
					self.$toasted.error('额度金额不能为0', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.institution.effective_start_date) {
					self.$toasted.error('有效期开始日期不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.institution.effective_end_date) {
					self.$toasted.error('有效期结束日期不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.saveInstitution();
			},

			//保存提交前，构建institution：处理standard使用规则：规则因子等
			buildInstitution() {
				let self = this;
				

				//1.设置规则因子 >> 后端处理
				//需要处理可用商户
				//a.餐饮-到店：需要处理 meal_merchant
				if(self.institution.expense_type_sub_category == 'REACH_SHOP'){
					
					if(self.mch_scope == 0){//不限
						
					}
					
				}
				

				//5.设置快捷商品码，用时间戳+2位随机数，仅限于新版餐饮
				// self.institution["barcode"] = new Date().getTime() + "" + parseInt(Math.random() * 100);
			},

			//保存制度
			saveInstitution() {
				let self = this;

				self.disabled = true;

				// 组装数据
				let postData = self.institution;

				postData['token'] = self.token;
				postData["outlet_id"] = self.outlet_id;

				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}



				//调用接口
				this.axios.post(this.GLOBAL.baseURI + 'saveCyProduct',
						postData2
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log('--- error->', error);
						// alert('保存失败')
						self.$toasted.error('保存失败', {
							position: 'top-center',
						}).goAway(2000)

						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							// alert('保存成功')

							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)

							self.$router.push({
								path: 'institutions',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)

							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
				//END
			},

			//重置商品
			resetInstitution(params) {
				console.log('--- 编辑商品，params ->', params)
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getCyProductsByBarcode', {
						params: {
							token: self.token,
							// outlet_id: params.outlet_id,
							outlet_id: self.outlet_id,
							barcode: params.no,
							nonce_str: params.nonce_str,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						console.log('--- getCyProductsByBarcode --- ,res->', data);

						if (data.code == 200) {
							var _specs = [],
								_tastes = [],
								_attachs = [];

							var institutions = data.data.institutions;
							if (institutions.length == 0) return;

							var _institution = institutions[0];

							self.institution = Object.assign({}, _institution); //重置institution
							self.institution["institution_id"] = _institution.id;
							self.institution["img_url"] = "";

							//菜品处理 start
							if (_institution.institution_type == 1) {

								// 多规格处理，组装specList
								if (institutions.length > 1) {
									for (var p of institutions) {
										var _spec = {};
										_spec["id"] = p.id;
										_spec["unit"] = p.unit;
										_spec["item_no"] = p.item_no;
										_spec["price"] = p.price;
										_spec["vip_price"] = p.vip_price;
										_spec["supply_price"] = p.supply_price;
										_specs.push(_spec);
									}
								}

								console.log("--- spes list->", _specs);

							} else {
								//关闭菜品特有的项
							}
							//菜品处理 end

							//商品图片处理
							// self.institutionImg, = _institution.img_url == 'HAVE' ? baseImgURI + _institution.id +
							// 	'.jpg?x-oss-process=style/sm-150x150&' + Math.random() : '',

							if (_institution.img_url == 'HAVE') {
								self.files.push({
									url: self.GLOBAL.baseImgURI + _institution.id +
										'.jpg?x-oss-process=style/sm-150x150&' + Math.random(),
									name: _institution.id + '.jpg'
								})
							}

							//规格、偏好、加料
							self.specList = _specs;
							self.tasteList = _institution.tastes;
							self.attachList = _institution.attachs;

							self.old_institution_name = _institution.institution_name;
							self.old_barcode = _institution.barcode;
						} else if (data.code == 404) {
							// Vue.toasted.error( '未找到商品', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })

							self.$router.push({
								name: '404'
							});

							return;
						} else {
							// alert(data.message);

							// Vue.toasted.error( data.message, {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })

							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}

					});
				//END

			},

			deleteInstitution() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'deleteCyProduct', {
						params: {
							token: self.token,
							outlet_id: self.institution.outlet_id,
							institution_id: self.institution.institution_id,
							del_same_barcode: 1, //是否删除同码多规格的所有商品
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						if (data.code == 200) {
							// alert('删除成功');

							// Vue.toasted.success( '删除成功', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							self.$toasted.success('删除成功', {
								position: 'top-center',
							}).goAway(2000)

							self.$router.push({
								path: 'institutions',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message);
							// Vue.toasted.error( data.message, {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}

					});
				//END
			},

			
			onChangeMchScope(ms){
				console.log('--- onChangeMchScope, ms ->',ms)
				let self = this;
				
				if(ms==1){
					self.query = '';
					self.merchant_list = [];
					$('#searchModal').modal('show');
				}
				else if(ms==2){
					$('#categoryModal').modal('show');
				}
			},
			
			onChangeSubExpenseType(){
				let self = this;
				
				self.mch_scope = 0;//费用子类型变更，重置可用商户
			},
			
			saveTakeAwayCategory(){
				let self = this;
				
				self.take_away_category = self.take_away_category_list[self.take_away_category_index].value;
				$('#categoryModal').modal('hide');
			},
			
			onChangeEndDate(){
				let self = this;
				
				if(self.end_date==1){
					self.institution.effective_end_date = '2099-12-31'//生效结束日期为长期时，设为2099年
				}
				else{
					self.institution.effective_end_date = '';
				}
			},
			
			searchMerchant(){
				let self = this;
				
				self.current_page = 1;
				self.page_size = 50;
				self.initMerchantData();
			},
			
			initMerchantData() {
				let self = this;
			
				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getMerchantList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							// start_date: self.start_date,//有效期
							// end_date: self.end_date,
							query: self.query,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
			
							self.merchant_list = data.data.merchant_list;
							
							self.start_date = data.data.start_date;
							self.end_date = data.data.end_date;
			
							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)
			
							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;
			
							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数
			
							// self.merchantlist = []; //重置
							// for (let merchant of data.data.list) {
							// 	// merchant.typeDesc = __STOCKTYPE[merchant.stock_type];
							// 	// merchant.stateDesc = merchant.stock_state?__STOCKSTATE[merchant.stock_state]:""
								
							// 	self.merchantlist.push(merchant);
							// }
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			
			},
			
			checkAll() {
				let self = this;
				self.check_all = !self.check_all;
				console.log('--- check all ---', self.check_all)
			
				if (self.check_all) {
					for (var i = 0; i < self.merchant_list.length; i++) {
						if (self.merchant_list[i].enabled == 0) continue;
						self.checkedIds.push(self.merchant_list[i].id);
					}
				} else {
					self.checkedIds = [];
				}
			},
			
			//////
			////
			isInArray(arr, value) {
				for (var i = 0; i < arr.length; i++) {
					if (value === arr[i]) {
						return true;
					}
				}
				return false;
			},
			
			//保留6位小数点
			//https://www.cnblogs.com/weekend001/p/3660661.html
			toDecimalSix(x) {
				var f = parseFloat(x);
				if (isNaN(f)) {
					return;
				}
				f = Math.round(x * 1000000) / 1000000;
				return f;
			},
			
			
			////
			initMap() {
				let self = this;
			
				//没有坐标，忽略
				if (!self.tempStore.longitude && !self.tempStore.latitude) {
					return;
				}
			
				//地图中心位置
				self.center = [];
				self.center.push(self.tempStore.longitude);
				self.center.push(self.tempStore.latitude);
				console.error('--- self center ->', self.center)
			
				//店铺位置标记
				let marker = {};
				marker.position = [self.tempStore.longitude, self.tempStore.latitude]
			
				self.markers = []; //先清空
				self.markers.push(marker);
			
				// 窗体信息
				let content = '<div class="text-center"><h5 class="text-red"> ' + self.tempStore.storeName +
					' </h5><h6> ' + (self.tempStore.storeAddress ? self.tempStore.storeAddress : '') + '</h6><div>' + (self
						.tempStore.phone ? self.tempStore.phone : '') + '</div></div>';
			
				self.window = {
					position: marker.position,
					content: content,
				}
			},
			
			back() {
				this.isShowLocation = false;
			},
			
			addStore() {
				let self = this;
			
				//初始化省份
				// let province_id = "350000";
				let province_id = self.outlet.state ? self.getAddressCodeIdByName(self.provinces, 'province_name', self
					.outlet.state) : '';
				console.log('--- province_id ->', self.outlet.state, province_id);
				self.initCities(province_id);
			
			
				//初始化城市
				// let city_id = "350200000000";
				let city_id = self.outlet.city ? self.getAddressCodeIdByName(self.cities, 'city_name', self.outlet.city) :
					'';
				console.log('--- city_id ->', self.outlet.city, city_id);
			
				let district_id = '';
				self.initDistricts(province_id, city_id, () => {
					if (self.outlet.street) {
						district_id = self.getDistrictIdByStreet(self.outlet.street)
					}
				});
				console.log('---- self.tempDistricts B ->', self.tempDistricts)
			
				console.log('---- district_id ->', district_id)
			
				//初始化区：如果上方没有设置，则默认第一个
				if (!district_id && self.tempDistricts.length > 0) {
					district_id = self.tempDistricts[0].district_id;
				}
			
			
				self.tempStore = {
					subStoreId: self.outlet.outlet_code,
					storeName: self.outlet.name,
					provinceId: province_id,
					cityId: city_id,
					districtId: district_id || '',
					storeAddress: self.outlet.street || '',
			
					latitude: self.address_info.latitude || '',
					longitude: self.address_info.longitude || '',
			
					phone: self.outlet.phone || '',
				};
			
				self.initMap();
			
			
				self.isShowLocation = true;
			},
			
			editStore() {
				let self = this;
			
				console.log('---- self.store @ editStore ->', self.store)
			
				// store.put("subStoreId", resultData.getString("subStoreId"));
				// store.put("storeName", resultData.getString("storeName"));
				// store.put("provinceId", resultData.getString("provinceId"));
				// store.put("cityId", resultData.getString("cityId"));
				// store.put("districtId", resultData.getString("districtId"));
				// store.put("storeAddress", resultData.getString("storeAddress"));
			
				// store.put("latitude", resultData.getString("latitude"));
				// store.put("longitude", resultData.getString("longitude"));
			
				// store.put("phone", resultData.getJSONArray("phoneList").getString(0));
			
				//初始化省份
				// let province_id = "350000";
				let province_id = self.store.provinceId;
				console.log('--- province_id ->', self.outlet.state, province_id);
				self.initCities(province_id);
			
			
				//初始化城市
				// let city_id = "350200000000";
				// let city_id = self.store.cityId;
				//310100000000
				let city_id = self.store.cityId + '000000'; //取回的城市代码，会少6个0
				console.log('--- city_id ->', self.outlet.city, city_id);
			
				let district_id = self.store.districtId + '000000'; //取回的区代码，会少6个0
				self.initDistricts(province_id, city_id);
				console.log('---- self.tempDistricts B ->', self.tempDistricts)
			
				console.log('---- district_id ->', district_id)
			
				//初始化区：如果上方没有设置，则默认第一个
				if (!district_id && self.tempDistricts.length > 0) {
					district_id = self.tempDistricts[0].district_id;
				}
			
			
				self.tempStore = Object.assign({}, self.store);
				// self.$set(self.tempStore, 'provinceId', province_id);
				self.$set(self.tempStore, 'cityId', city_id); //取回的城市代码，会少6个0
				self.$set(self.tempStore, 'districtId', district_id);
			
				self.initMap();
			
				self.isShowLocation = true;
			},
			
			getAddressCodeIdByName(arr, key, name) {
				// console.log('--- name @ getAddressCodeIdByName ->', name)
				let self = this;
			
				let tmpArr = arr.filter((item) => {
					if (item[key] != undefined) {
						return item[key].indexOf(name) != -1
					} else {
						return false
					}
				})
			
				if (tmpArr.length > 0) {
					if (key == 'city_name')
						return tmpArr[0].city_id;
			
					if (key == 'province_name')
						return tmpArr[0].province_id;
				}
				return '';
			},
			
			
			initCities(id) {
				let self = this;
			
				self.tempCities = self.cities.filter((item) => {
					return item.province_id == id
				})
			},
			
			initDistricts(provinceid, cityid, callback) {
				let self = this;
			
				self.tempDistricts = self.districts.filter((item) => {
					return item.province_id = provinceid && item.city_id == cityid
				})
				console.log('---- self.tempDistricts ->', self.tempDistricts)
				if (typeof callback == 'function') {
					callback()
				}
			},
			
			getDistrictIdByStreet(street) {
				let self = this;
			
				let tempArr = self.tempDistricts.filter((item) => {
					return street.indexOf(item.district_name) != -1
				})
				console.log('---- districts ->', tempArr)
			
				if (tempArr.length > 0) {
					return tempArr[0].district_id;
				}
				return '';
			},
			
			onChangeProvince() {
				let self = this;
			
				let pid = self.tempStore.provinceId;
				console.log('---- pid ->', pid)
				self.initCities(pid);
			
				self.tempDistricts = []; //必须清空区
			},
			
			onChangeCity() {
				let self = this;
			
				let pid = self.tempStore.provinceId;
				let cid = self.tempStore.cityId;
				console.log('---- pid,cid ->', pid, cid)
				self.initDistricts(pid, cid);
			},
			
			onSearchResult(pois) {
				console.log('---- onSearchResult, e ->', pois)
				console.log('---- location ->', pois[0].location);
			
				let latSum = 0
				let lngSum = 0
				if (pois.length > 0) {
					pois.forEach(poi => {
						const {
							lng,
							lat
						} = poi
						lngSum += lng
						latSum += lat
					})
					console.log('--- lng, lat->', lngSum / pois.length, latSum / pois.length)
					const center = {
						// lng: parseFloat((lngSum / pois.length).toFixed(6)),
						// lat: parseFloat((latSum / pois.length).toFixed(6))
						
						lng: this.toDecimalSix((lngSum / pois.length).toFixed(6)),
						lat: this.toDecimalSix((latSum / pois.length).toFixed(6))
					}
			
					this.chooseLocation(center.lng, center.lat)
				}
			},
			
			
			chooseLocation(lng, lat) {
				let self = this;
			
				// 这里通过高德 SDK 完成。
				var geocoder = new AMap.Geocoder({
					radius: 1000,
					extensions: 'all'
				})
				geocoder.getAddress([lng, lat], function(status, result) {
					console.log('--- status, res ->', status, result)
					if (status === 'complete' && result.info === 'OK') {
						if (result && result.regeocode) {
			
							// self.tempStore.longitude = lng;
							// self.tempStore.latitude = lat;
							// self.tempStore.storeAddress = result.regeocode.formattedAddress;
			
							let _data = {
								longitude: lng,
								latitude: lat,
								address: result.regeocode.formattedAddress,
								province: result.regeocode.addressComponent.province,
								city: result.regeocode.addressComponent.city || result.regeocode
									.addressComponent.province, //城市为空表示直辖市 
								district: result.regeocode.addressComponent.district
							}
			
							self.$refs.myConfirm.show(result.regeocode.formattedAddress, {
								titleText: '定位',
								type: 'confirm',
								data: _data
							})
						}
					}
				})
			},
			
			confirmHandler(type, data) {
				let self = this;
			
				console.log('--- confirmHandler ->', type, data)
			
				if (type == 'clickConfirm') {
					console.log('用户点击了确认')
			
					// self.tempStore.longitude = data.longitude;
					// self.tempStore.latitude = data.latitude;
					// self.tempStore.storeAddress = data.address;
			
					self.$set(self.tempStore, 'longitude', data.longitude);
					self.$set(self.tempStore, 'latitude', data.latitude);
					self.$set(self.tempStore, 'storeAddress', data.address);
			
			
					//初始化省份
					// let province_id = "350000";
					let province_id = self.getAddressCodeIdByName(self.provinces, 'province_name', data.province);
					console.log('--- province_id ->', data.province, province_id);
					self.initCities(province_id);
			
			
					//初始化城市
					// let city_id = "350200000000";
					let city_id = self.getAddressCodeIdByName(self.cities, 'city_name', data.city);
					console.log('--- city_id ->', data.city, city_id);
			
					let district_id = '';
					self.initDistricts(province_id, city_id, () => {
						if (data.district) {
							district_id = self.getDistrictIdByStreet(data.district)
						}
					});
			
					self.$set(self.tempStore, 'provinceId', province_id);
					self.$set(self.tempStore, 'cityId', city_id);
					self.$set(self.tempStore, 'districtId', district_id);
			
					console.log('--- self.tempStore @confirmHandler ->', self.tempStore)
			
					self.initMap();
				}
			
				if (type == 'clickCancel') {
					console.log('用户点击了取消')
				}
			
			},
			
			saveStore(){
				let self = this;
				
				// @RequestParam String subStoreId, @RequestParam String storeName, 
				// @RequestParam String provinceId, @RequestParam String cityId, @RequestParam String districtId, 
				// @RequestParam String storeAddress, 
				// @RequestParam String latitude, @RequestParam String longitude, 
				// String phone
				
				//校验
				if (!self.tempStore.storeName) {
					self.$toasted.error('请输入店铺名称', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.tempStore.phone) {
					self.$toasted.error('请输入店铺电话', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
								
				if (!self.tempStore.provinceId) {
					self.$toasted.error('请选择省份', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.tempStore.cityId) {
					self.$toasted.error('请选择城市', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.tempStore.districtId) {
					self.$toasted.error('请选择行政区', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.tempStore.storeAddress) {
					self.$toasted.error('请输入店铺地址', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.tempStore.longitude || !self.tempStore.latitude) {
					self.$toasted.error('请在地图上选点定位店铺位置', {
						position: 'top-center',
						duration: '3000',
					})
					return;
				}
				
				self.disabled = true;
				
				console.log('--- self.tempStore ->', self.tempStore)
				
				// 组装数据
				let postData = self.tempStore;
				
				postData['token'] = self.token;
				postData["outlet_id"] = self.outlet_id;
				
				let formData = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					formData.append(key, postData[key]);
				}
				
				self.axios.post(self.GLOBAL.baseURI + 'saveStoreInfo', 
						formData
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
				
						self.disabled = false;
				
						if (data.code == 200) {
							if(data.data.result_code == 'success'){
								let msg = self.isEdit?'保存成功':'保存成功，首次设置，请稍候刷新查看结果';
								self.$toasted.success(msg, {
									position: 'top-center',
									duration: '4000',
								})
								
								//刷新餐道门店数据
								setTimeout(()=>{
									self.initData();
								},1000)
								
								self.isShowLocation = false;
							}
							else{
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
							
						} else if (data.code == 101) {
							//登录超时
							location.href = "inddex.html";
						} else {
							self.$toasted.error(data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				
			},

		},
	}
</script>

<style scoped>
	.amap-wrapper {
		width: 100%x;
		height: 450px;
	}
	
	.search-box {
		width: 100%;
	}
</style>
